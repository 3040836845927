/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import { getWarningMessage } from 'components/placeorder_v2/MarginTypeWarning/util';
import { COUNTRY_ALLOWED_FOR_LEVEL2 } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { find, groupBy, merge, not, prop } from 'helpers/ramda';
import { isUserIndian } from 'helpers/user';
import {
  isDepositDisabled,
  isMobileOrTablet,
  isUserBankWithdrawalPermissionDisabled,
  isUserSpotTradingBlocked,
  isUserWithdrawalPermissionDisabled,
  toTitleCase,
} from 'helpers/utils';
import { isTruthy } from 'ramdax';
import IState from 'types/Istore';
import IUser, { Banners } from 'types/IUser';

export const orderHistorySelector = state => state.orderHistory;
export const transferFundsHistorySelector = state => state.transferFundsHistory;

export const fillsSelector = state => state.fills;
export const openStopOrdersSelector = state => state.holdings.openStopOrders;
export const openOrdersSelector = state => state.holdings.openOrders;
export const transactionsSelector = (state: IState) => state.transactions;
export const otherSelector = (state: IState) => state.other;
export const appDrawerSelector = (state: IState) => state.appDrawer;

export const userSelector = (state: IState) => state.user;
export const getUserFirstName = createSelector(
  [userSelector],
  (user: IUser) => user.firstName || ''
);
export const getUserLastName = createSelector(
  [userSelector],
  (user: IUser) => user.lastName || ''
);

const getUserFullName = createSelector(
  [getUserFirstName, getUserLastName],
  (firstName, lastName) => `${toTitleCase(firstName)} ${toTitleCase(lastName)}`
);

export const getUserAvailableLimit = createSelector(
  [userSelector],
  user => user.availableLimit
);

export const getUserId = (state: IState) => state.user.id;
export const userProfileSelector = createSelector([userSelector], user => user.profile);
export const userDepositsSelector = createSelector(
  [userProfileSelector],
  userProfile => userProfile?.deposit_info
);

const userHasZeroDepositsSelector = createSelector(
  [userDepositsSelector],
  userDeposits => userDeposits?.length === 0
);

const userDepositOffersSelector = createSelector(
  [userProfileSelector],
  userProfile => userProfile?.net_deposit_for_offers
);

export const isUserKycDoneSelector = (state: IState) => state.user.is_kyc_done;

export const isBankAccountAddedSelector = createSelector(
  [userSelector],
  user => user?.isBankAccountAdded
);
export const isUserAvailableSelector = createSelector([userSelector], user =>
  Boolean(user.id)
);

export const userAddressProofSelector = createSelector(
  [userSelector],
  user => user?.proof_of_address_status
);

export const userIdentityProofSelector = createSelector(
  [userSelector],
  user => user?.proof_of_identity_status
);

export const userPhoneVerificationTokenSelector = createSelector(
  [userSelector],
  user => user?.phone_verification_token
);

export const getUserNameById = (id: number, user: IUser) => {
  if (user.id === id) {
    return user.userName;
  }
  const existUser = find(account => account.id === id, user.sub_accounts);
  return existUser ? existUser.nick_name : '';
};

export const tradePreferencesSelector = (state: IState) => state.tradePreference;

export const overlaySelector = (state: IState) => state.overlay;
export const expiredProductsSelector = (state: IState) => state.expiredProducts;

export const marketShowLoaderSelector = (state: IState) => state.markets.showLoader;

export const marketsSelector = (state: IState) => state.markets.marketProducts;

// @ts-ignore
export const tabReactiveTimeSelector = createSelector(
  [otherSelector],
  prop('tabReactiveTime')
);

// @ts-ignore
export const tabInactiveTimeSelector = createSelector(
  [otherSelector],
  prop('tabInactiveTime')
);

export const userPreferencesSelector = createSelector([userSelector], user =>
  prop('preferences', user)
);

export const userOrderTypePreferenceSelector = createSelector(
  [userPreferencesSelector],
  userPreferences => userPreferences?.order_type_preference
);

export const userSubAccountsSelector = createSelector(
  [userSelector],
  user => user.sub_accounts
);

export const userRegistrationDateSelector = createSelector(
  [userSelector],
  user => user.registration_date
);

// @ts-ignore
export const allPrefrencesSelector = createSelector(
  [userPreferencesSelector, tradePreferencesSelector],
  (userPrefrences, tradePrefrences) => {
    return merge(userPrefrences, tradePrefrences);
  }
);

// @ts-ignore
export const favoriteIdsSelector = createSelector(
  [userPreferencesSelector],
  prop('favorites')
);

// @ts-ignore
export const toggleWatchListTickerTapeSelector = createSelector(
  [userPreferencesSelector],
  prop('showOnlyFavsOnTicker')
);

// @ts-ignore
export const userMarginModeSelector: () => MARGIN_MODE = createSelector(
  [userSelector],
  prop('margin_mode')
);

// export const tickerTapeProductListSelector = createSelector(
//   [productsSelector , favoriteIdsSelector , userPreferencesSelector],
// )

// const contractTypeSelector = (state: IState) =>
//   state.trade.selectedContractType;

// export const filteredMarketsProductSelector = createSelector(
//   [marketsSelector, contractTypeSelector],
//   (products, selectedContractType) =>
//     products.filter(product => {
//       if (selectedContractType === 'options') {
//         return isOptions(product.contract_type) && product.state === 'live';
//       } else if (selectedContractType === 'turbo_options') {
//         return isTurbo(product.contract_type) && product.state === 'live';
//       } else {
//         return (
//           product.contract_type.includes(selectedContractType) &&
//           product.state === 'live'
//         );
//       }
//     })
// );

// export const dropdownProductListSelector = createSelector(
//   [productsListSelector],
//   productsList => newSortedProductsList(productsList)
// )

export const paginationAllSelector = state => state.pagination;

export const paginationStateSelector = (state, table) => state.pagination[table];

export const popoverSelector = (state: IState) => ({
  shouldClosePrevPopover: state.popover.shouldClosePrevPopover,
});

export const tableFiltersSelector = (state, table) => state.holdingsTableFilter[table];

export const transactionState = createSelector(transactionsSelector, transactions => ({
  dataByCurrency: transactions.dataByCurrency,
  pages: transactions.transactionsPages,
  totalTransactions: transactions.totalTransactions,
  apiPageSize: transactions.apiPageSize,
  after: transactions.after,
  loading: transactions.isTransactionsLoading,
  pageNumberByCurrency: transactions.pageNumberByCurrency,
}));

export const pageInactiveSelector = createSelector(
  [otherSelector],
  prop('isPageInactive')
);

export const pageInactiveOnMobileSelector = createSelector(
  pageInactiveSelector,
  isPageInactive => (isMobileOrTablet() ? isPageInactive : false)
);

export const socketDisconnectedOnPageInactiveSelector = createSelector(
  [otherSelector],
  prop('socketDisconnectedOnPageInactive')
);

export const currentOverlayShownSelector = createSelector(overlaySelector, overlayMap =>
  Object.keys(overlayMap).find(v => !!overlayMap[v])
);

export const activeTabSelector = createSelector([appDrawerSelector], prop('activeTab'));

export const detoMSPSelector = state =>
  state.settings && state.settings.deto_msp ? Number(state.settings.deto_msp) : 0;

export const playDepositVideoSelector = state => state.settings.play_deposit_video;

export const playWithdrawalVideoSelector = state => state.settings.play_withdrawal_video;

export const settingsSelector = (state: IState) => state.settings;

export const inrConversionRateSelector = (state: IState) =>
  state.settings && state.settings?.fiat_to_usd?.asset_to_fiat_value
    ? Number(state.settings.fiat_to_usd.asset_to_fiat_value)
    : 85;

export const getAssetSymbolOptionChain = state => state.optionsChain.assetSymbol;

// @ts-ignore
export const portfolioMarginParamsSelector = createSelector(
  [settingsSelector],
  prop('portfolio_margin_params')
);

// @ts-ignore
export const portfolioEnableMinBalanceSelector = createSelector(
  [portfolioMarginParamsSelector],
  prop('portfolio_enable_balance_threshold')
);

// @ts-ignore
export const portfolioEnabledContractsSelector = createSelector(
  [portfolioMarginParamsSelector],
  prop('portfolio_enabled_contracts')
);

// @ts-ignore
export const enabledPortfolioAssetsSelector = createSelector(
  [portfolioMarginParamsSelector],
  prop('enabled_portfolios')
);

export const maxAccountsSelector = createSelector(userSelector, user => {
  const userObj = user.main_account || user;
  const accountLimits = {
    portfolio: {},
    ...userObj.tracking_info?.sub_account_config,
    currentLimits: {
      portfolio: {},
    },
    maxAccounts: 0,
  };

  accountLimits.maxAccounts =
    accountLimits.cross + accountLimits.isolated + accountLimits.portfolio;
  // reduce(R.add, 0, R.values(accountLimits.portfolio || {}));

  let subAccounts = user.main_account?.sub_accounts || user.sub_accounts;

  subAccounts = user.is_sub_account
    ? [user.main_account, ...subAccounts]
    : [user, ...subAccounts];

  const groupByMarginTypeFun = groupBy(prop('margin_mode'));
  const groupByMarginType = groupByMarginTypeFun(subAccounts);
  [MARGIN_MODE.ISOLATED, MARGIN_MODE.CROSS, MARGIN_MODE.PORTFOLIO].forEach(marginMode => {
    accountLimits.currentLimits[marginMode] = groupByMarginType[marginMode]?.length || 0;
  });
  // groupByMarginType[MARGIN_MODE.PORTFOLIO]?.forEach(obj => {
  //   if (!accountLimits.currentLimits[MARGIN_MODE.PORTFOLIO][obj.pf_index_symbol]) {
  //     accountLimits.currentLimits[MARGIN_MODE.PORTFOLIO][obj.pf_index_symbol] = 0;
  //   }
  //   accountLimits.currentLimits[MARGIN_MODE.PORTFOLIO][obj.pf_index_symbol] += 1;
  // });
  return accountLimits;
});

const isUserIndianSelector = createSelector([userSelector], user => isUserIndian(user));

/** @description  if backend sends disabled for deposit in permission returns true there are many conditions for disabling deposit regarding kyc being
 * used throughout app, this is unrelated to that.
 */
const isUserPermissionForDepositDisabledSelector = createSelector(
  [userSelector],
  user => {
    return user?.permissions?.fiat_deposit === 'disabled';
  }
);

const isUserPermissionForLiveChatSelector = createSelector([userSelector], user => {
  return user?.permissions?.is_chatsupport_enabled === true;
});

const isUserPermissionForBuyCryptoDisabledSelector = createSelector(
  [userSelector],
  user => {
    return user?.permissions?.crypto_deposit === 'disabled';
  }
);

const isUserDepositDisabledSelector = createSelector([userSelector], user =>
  isDepositDisabled(user)
);

const isUserLoggedInSelector = createSelector([userSelector], user =>
  isTruthy(user?.token)
);

const selectUserBanners = createSelector([userSelector], (user): Banners => {
  const banners: string[] = user.tracking_info?.banners || [];

  return banners.reduce((bannersAcc, banner) => {
    // eslint-disable-next-line no-param-reassign
    bannersAcc[banner] = true;
    return bannersAcc;
  }, {});
});

const isUserAllowedForLevel2Verification = createSelector([userSelector], user => {
  return COUNTRY_ALLOWED_FOR_LEVEL2.includes(user.country?.toLowerCase());
});

const selectIsWithdrawalEnabled = createSelector(
  [userSelector],
  user => user.is_withdrawal_enabled
);

const selectWithdrawalBlockedTill = createSelector(
  [userSelector],
  user => user.withdrawal_blocked_till
);

const selectWithdrawalPendingCount = createSelector(
  [userSelector],
  user => user.withdrawalPendingCount
);

const selectUserCountry = createSelector([userSelector], user => user.country);

const selectCountryFromIp = createSelector([otherSelector], other => other.countryFromIp);

const selectKycRejectionReason = createSelector(
  [userSelector],
  user => user.kyc_rejection_reason
);

const userPermissionsSelector = createSelector([userSelector], user => user.permissions);

const isUserSubAccountLoginEnabledSelector = createSelector(
  [userSelector],
  user => user.sub_account_permissions?.is_login_enabled
);

const isUserWithdrawalPermissionDisabledSelector = createSelector([userSelector], user =>
  isUserWithdrawalPermissionDisabled(user)
);

const isUserSpotTradingDisabledSelector = createSelector(
  [userPermissionsSelector],
  userPermissions => isUserSpotTradingBlocked(userPermissions)
);

const showConvertBlockedPopupSelector = createSelector(
  [otherSelector],
  other => other.showConvertBlockedPopup
);

const showOtpSuccessPopupSelector = createSelector(
  [otherSelector],
  other => other.showOtpSuccessPopup
);

export const withdrawalsHistorySelector = (state: IState) => state.withdrawalsHistory;
const selectUserLast30DayTradingVolume = createSelector(
  [userProfileSelector],
  profile => profile?.last_30_days_trading_volume
);

const selectUserRegistrationDate = createSelector(
  [userProfileSelector],
  profile => profile?.registration_date
);

const selectUserKycVerifiedDate = createSelector(
  [userSelector],
  user => user.kyc_verified_on
);

const selectUserLoginCount = createSelector(
  [userProfileSelector],
  profile => profile?.login_count
);

const selectUserPermissionsForBankWithdrawalEnabled = createSelector(
  [userPermissionsSelector],
  permissions => not(isUserBankWithdrawalPermissionDisabled(permissions))
);

const selectIsUserIndianCorporateAccount = createSelector(
  [isUserIndianSelector, userProfileSelector],
  (isIndianUser, profile) => profile?.user_role === 'corporate' && isIndianUser
);

const selectIsCorporateUser = createSelector(
  [userProfileSelector],
  profile => profile?.user_role === 'corporate'
);

const selectUserTrackingInfo = createSelector([userSelector], user => user.tracking_info);

const selectUserKYCVendorFromTrackingInfo = createSelector(
  [selectUserTrackingInfo],
  trackingInfo => trackingInfo?.vendor
);

const selectIsUserDuplicateAccountDelete = createSelector(
  [selectUserTrackingInfo],
  trackingInfo => trackingInfo?.duplicate_account_deleted
);

const selectUserDuplicateKycEmail = createSelector(
  [selectUserTrackingInfo],
  trackingInfo => trackingInfo?.duplicate_kyc_email
);
const selectIsUserKYCVendorHyperverge = createSelector(
  [selectUserKYCVendorFromTrackingInfo],
  vendor => vendor === 'hyperverge'
);

const userMarketingOfferValueSelector = createSelector(
  [selectUserTrackingInfo],
  tracking_info => tracking_info?.marketing_offer_value
);

const selectAppReviewFromTrackingInfo = createSelector(
  [selectUserTrackingInfo],
  trackingInfo => {
    return trackingInfo?.app_review;
  }
);

const selectUserOfferInfo = createSelector(
  [userProfileSelector],
  profile => profile?.offer_info
);

const selectUserOfferClaimed = createSelector(
  [userProfileSelector],
  profile => profile?.offer_claimed
);

const selectIsAlpyneOnrampEnabled = createSelector(
  [userPermissionsSelector],
  permissions => {
    return permissions?.is_alpyne_onramp_enable ?? false;
  }
);

const selectIsOnmetaOnrampEnabled = createSelector(
  [userPermissionsSelector],
  permissions => {
    return permissions?.is_onmeta_onramp_enable ?? false;
  }
);

const showKycShareConsentPopupSelector = createSelector(
  [userSelector],
  user => user?.kycShareConsentPopupConfig
);

const marginTypeWarningSelector = createSelector(
  [
    userMarginModeSelector,
    enabledPortfolioAssetsSelector,
    (_, selectedProduct) => selectedProduct,
  ],
  (userMarginMode, enabledPfs, selectedProduct) =>
    getWarningMessage({
      marginMode: userMarginMode as string,
      selectedProduct: selectedProduct || {},
      enabledPfs,
    })
);

const marketOrderUserPreferenceSelector = createSelector([userSelector], user =>
  prop('marketOrder', user?.preferences)
);

const fatFingerProtectionSelector = createSelector([userSelector], user =>
  prop('fatFingerProtection', user?.preferences)
);

const alwaysShowOnMobileSelector = createSelector([userSelector], user =>
  prop('alwaysShowOnMobile', user?.preferences)
);

const orderPlacedFromChartsSelector = createSelector([userSelector], user =>
  prop('orderPlacedFromCharts', user?.preferences)
);

const showOrderOnChartSelector = createSelector([userSelector], user =>
  prop('showOrderOnChart', user?.preferences)
);

const scalpingDataSelector = state => {
  return {
    scalperOffer: state?.tradePreference?.scalperOffer,
    appliedScalperOffer: state?.offers?.appliedOffers?.futures_scalper_offer,
  };
};

const scalperOfferMetadataSelector = state => {
  const {
    applicable_product_symbols: applicableProductSymbols = [],
    scalper_offer_time_range: scalperOfferTimeRange = 0,
  } = state?.offers?.activeOffers?.futures_scalper_offer?.meta_data || {};

  return {
    applicableProductSymbols,
    scalperOfferTimeRange,
  };
};

const tradeSuccessShareCardDataSelector = (state: IState) => ({
  tradeSucessShareCardDataLoading: state.user.tradeSucessShareCardDataLoading,
  tradeSucessShareCardData: state.user.tradeSucessShareCardData,
});

const isUserBankDeleteBankDisabledSelector = createSelector(
  [userPermissionsSelector],
  userPermissions => userPermissions?.delete_bank === 'disabled' || false
);

const userComplianceCommentSelector = createSelector(
  [userPermissionsSelector],
  userPermissions => userPermissions?.compliance_comment
);
const selectDisableFaceAuthEmailPass = (state: IState) => state.other.disable2faFaceAuth;

const shouldClearOtpValuesSelector = (state: IState) => state.other.shouldClearOtpValues;

const isUserPermissionDepositDisabledSelector = createSelector(
  [userPermissionsSelector],
  userPermissions => userPermissions?.deposit === 'disabled'
);

const selectIsTabInactive = (state: IState) => state.other.isTabInactive;

export {
  tradeSuccessShareCardDataSelector,
  scalpingDataSelector,
  scalperOfferMetadataSelector,
  alwaysShowOnMobileSelector,
  fatFingerProtectionSelector,
  isUserAllowedForLevel2Verification,
  isUserDepositDisabledSelector,
  isUserIndianSelector,
  isUserLoggedInSelector,
  isUserPermissionForBuyCryptoDisabledSelector,
  isUserPermissionForDepositDisabledSelector,
  isUserSpotTradingDisabledSelector,
  isUserSubAccountLoginEnabledSelector,
  isUserWithdrawalPermissionDisabledSelector,
  marginTypeWarningSelector,
  marketOrderUserPreferenceSelector,
  orderPlacedFromChartsSelector,
  selectAppReviewFromTrackingInfo,
  selectCountryFromIp,
  selectIsAlpyneOnrampEnabled,
  selectIsCorporateUser,
  selectIsOnmetaOnrampEnabled,
  selectIsUserDuplicateAccountDelete,
  selectIsUserIndianCorporateAccount,
  selectIsUserKYCVendorHyperverge,
  selectIsWithdrawalEnabled,
  selectKycRejectionReason,
  selectUserBanners,
  selectUserCountry,
  selectUserDuplicateKycEmail,
  selectUserKYCVendorFromTrackingInfo,
  selectUserKycVerifiedDate,
  selectUserLast30DayTradingVolume,
  selectUserLoginCount,
  selectUserOfferClaimed,
  selectUserOfferInfo,
  selectUserPermissionsForBankWithdrawalEnabled,
  selectUserRegistrationDate,
  selectUserTrackingInfo,
  selectWithdrawalBlockedTill,
  selectWithdrawalPendingCount,
  showConvertBlockedPopupSelector,
  showKycShareConsentPopupSelector,
  showOrderOnChartSelector,
  showOtpSuccessPopupSelector,
  userDepositOffersSelector,
  userHasZeroDepositsSelector,
  userMarketingOfferValueSelector,
  userPermissionsSelector,
  getUserFullName,
  isUserBankDeleteBankDisabledSelector,
  userComplianceCommentSelector,
  isUserPermissionForLiveChatSelector,
  selectDisableFaceAuthEmailPass,
  shouldClearOtpValuesSelector,
  isUserPermissionDepositDisabledSelector,
  selectIsTabInactive,
};
